import { PlusOutlined } from "@ant-design/icons";
import { Button, Collapse, Result, Spin } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import AddServiceCategory from "../../components/AddServiceCategory";
import DeleteServiceCategory from "../../components/DeleteServiceCategory";
import { useSelector } from "react-redux";
import OutletItem from "./OutletItem";
import Swal from "sweetalert2";
import AddOutletModal from "./AddOutletModal";

const Outlets = () => {
  // Get HOTEL SERVICES from store
  const hotelServices = useSelector(
    (state) => state.hotelReducer?.hotel?.services
  );
  // Find THIS service from the fetched services
  const hotelService = hotelServices.find(
    (service) => service?.name === "Outlets"
  );

  const hotelLoading = useSelector((state) => state.hotelReducer.loadingHotel);

  const [data, setData] = useState([]);
  const [addItemModal, setAddItemModal] = useState(false);
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clickedCategory, setClickedCategory] = useState({
    id: null,
    name: "",
  });
  const user = JSON.parse(localStorage.getItem("user"));

  const fetchService = () => {
    setLoading(true);

    axios
      .get(
        `${import.meta.env.VITE_APP_BASE_API_URL
        }/serviceCategories/get-categories?service=${hotelService?.id ? hotelService?.id : 30
        }`,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data)
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchService();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelServices]);

  const genExtra = (id, catname) => (
    <div className="actions">
      <PlusOutlined
        onClick={(event) => {
          let temp = clickedCategory;
          temp.id = id;
          temp.name = catname;
          setClickedCategory(temp);
          setAddItemModal(true);
          event.stopPropagation();
        }}
      />
    </div>
  );

  const handleDeleteClick = (e, item) => {
    e.stopPropagation();
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#0d375c",
      confirmButtonText: "Yes, delete it!",
      preConfirm: () => {
        return axios
          .delete(
            `${import.meta.env.VITE_APP_BASE_API_URL}/items?id=${item.id}`,
            {
              headers: {
                Authorization: `Bearer ${user.data.accessToken}`,
              },
            }
          )
          .then(() => {
            // setIsDeleting((prev) => !prev);
            fetchService();
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          });
      },
    });
  };
  const items = data?.serviceCategories?.map((cat) => {
    return {
      key: cat.id,
      label: cat.name?.english,

      children: (
        <Collapse
          accordion
          items={cat.items.map((item) => {
            return {
              key: item.id,
              label: item?.name?.english,
              children: <OutletItem refetch={fetchService} item={item} />,
              extra: (
                <Button
                  type="dashed"
                  danger
                  size="small"
                  onClick={(e) => handleDeleteClick(e, item)}
                >
                  Delete Item
                </Button>
              ),
            };
          })}
        />
      ),
      extra: genExtra(cat.id, cat?.name?.english),
    };
  });
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 16,
        }}
        className="top-controls"
      >
        <Button
          onClick={() => setAddCategoryModal(true)}
          type="primary"
          style={{ marginRight: 16, textAlign: "right" }}
        >
          Add Category
        </Button>
        <Button
          type="dashed"
          danger
          onClick={() => setDeleteCategoryModal(true)}
        >
          Delete Category
        </Button>
      </div>

      {loading || hotelLoading ? (
        <Spin
          style={{ position: "absolute", left: "50%", top: "50%" }}
          size="large"
        />
      ) : items?.length === 0 ? (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, there's no available categories, please contact your adminstrator"
        />
      ) : (
        <Collapse accordion items={items} />
      )}

      {/* NOTE:  lama teegi t3ml add category pass serviceID */}
      <AddServiceCategory
        setAddCategoryModal={setAddCategoryModal}
        addCategoryModal={addCategoryModal}
        refetch={fetchService}
        serviceID={hotelService?.id}
      />
      <DeleteServiceCategory
        refetch={fetchService}
        setDeleteCategoryModal={setDeleteCategoryModal}
        deleteCategoryModal={deleteCategoryModal}
        categories={data?.serviceCategories}
      />

      <AddOutletModal
        addItemModal={addItemModal}
        setAddItemModal={setAddItemModal}
        refetch={fetchService}
        clickedCategory={clickedCategory}
      />
    </>
  );
};

export default Outlets;
