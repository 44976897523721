import { Form, Input, Modal, TimePicker } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import UploadItemIcon from "../../components/UploadIcon";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";

const AddOutletModal = ({
  setAddItemModal,
  refetch,
  addItemModal,
  clickedCategory,
}) => {

  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [image, setImage] = useState("");

  const onFinish = async (values) => {
    // Set the image state with the icon image URL or path
    setImage(values.images);
    setLoading(true);

    // Check if the icon is present
    if (!values.images || values.images === '') {
      // If no icon is selected, show an error
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select an icon image.",
      });
      setLoading(false);
      return;
    }

    const payload_services = {
      name: {
        english: values.itemName,
        russian: values.itemNameRU,
        arabic: values.itemNameAR,
      },
      active: true,
      hotel: selectedHotel.hotelID || 100378095,
    };

    try {
      const serviceResponse = await axios.post(
        `${import.meta.env.VITE_APP_BASE_API_URL}/services`,
        payload_services,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );

      // Build the payload_item
      const payload_item = {
        name: {
          english: values.itemName,
          russian: values.itemNameRU,
          arabic: values.itemNameAR,
        },
        description: {
          english: values.description,
          russian: values.descriptionRU,
          arabic: values.descriptionAR,
        },
        price: 0,
        service: serviceResponse.data.createdService.id,
        serviceCategory: clickedCategory.id,
        extraFields: {
          phone: values.phone,
          outletType: {
            english: values.outletType,
            russian: values.outletTypeRU,
            arabic: values.outletTypeAR,
          },
          location: {
            name: {
              english: values.locationName,
              arabic: values.locationNameAR,
              russian: values.locationNameRU,
            },
          },
          description: {
            english: values.description,
            russian: values.descriptionRU,
            arabic: values.descriptionAR,
          },
        },
        icon: values.images, // Add the image as the icon in the payload
      };
      console.log(payload_item)
      // Conditionally add `openingHours` only if `timeFrom` and `timeTo` are present
      if (values.timeFrom && values.timeTo) {
        payload_item.extraFields.openingHours = {
          from: values.timeFrom.format("HH:mm"),
          to: values.timeTo.format("HH:mm"),
          description: {
            english: values.openingHoursDesc,
            arabic: values.openingHoursDescAR,
            russian: values.openingHoursDescRU,
          },
        };
      }

      console.log("payload_item ", payload_item);

      // Then, create the item with the icon in `extraFields`
      await axios.post(
        `${import.meta.env.VITE_APP_BASE_API_URL}/items`,
        payload_item,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );

      // Reset the form and refetch data
      form.resetFields();
      refetch();
      setAddItemModal(false);

    } catch (error) {
      console.log(error);
      // Handle any error in either request
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred",
      });
    } finally {
      setLoading(false);
    }
  };






  const handleCancel = () => {
    form.resetFields();
    setAddItemModal(false);
  };

  return (
    <Modal
      confirmLoading={loading}
      okText={"Create item"}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            console.log("values", values);
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={handleCancel}
      open={addItemModal}
      centered
    >
      <Form form={form} layout="vertical" name="addOutletModal">
        <Form.Item
          label="Photo"
          name="images"
          rules={[
            {
              required: true,
              message: "Please upload the photo!",
            },
          ]}
        >
          <UploadItemIcon />
        </Form.Item>

        <Form.Item
          name="itemName"
          label="Item Name"
          rules={[
            {
              required: true,
              message: "Please input the item name!",
            },
            {
              whitespace: true,
              message: "Please input valid item name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="itemNameRU"
          label="Item Name in Russian"
          rules={[
            {
              required: true,
              message: "Please input the item name!",
            },
            {
              whitespace: true,
              message: "Please input valid item name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="itemNameAR"
          label="Item Name in Arabic"
          rules={[
            {
              required: true,
              message: "Please input the item name!",
            },
            {
              whitespace: true,
              message: "Please input valid item name!",
            },
          ]}
        >
          <Input dir="rtl" />
        </Form.Item>
        <Form.Item
          name="outletType"
          label="Outlet Type"
          rules={[
            {
              whitespace: true,
              message: "Please input valid Outlet Type!",
            },
            {
              required: true,
              message: "Please input Outlet Type!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="outletTypeRU"
          label="Outlet Type in Russian"
          rules={[
            {
              required: true,
              message: "Please input Outlet Type!",
            },
            {
              whitespace: true,
              message: "Please input valid Outlet Type!!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="outletTypeAR"
          label="Outlet Type in Arabic"
          rules={[
            {
              required: true,
              message: "Please input Outlet Type!",
            },
            {
              whitespace: true,
              message: "Please input valid Outlet Type!",
            },
          ]}
        >
          <Input dir="rtl" />
        </Form.Item>
        <Form.Item>{/* <UploadMultipleImages /> */}</Form.Item>
        <Form.Item
          name="phone"
          label="Restaurant Phone"
          type="number"

        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"

        >
          <ReactQuill />
        </Form.Item>
        <Form.Item
          label="Description in Russian"
          name="descriptionRU"

        >
          <ReactQuill />
        </Form.Item>
        <Form.Item
          label="Description in Arabic"
          name="descriptionAR"
        >
          <ReactQuill />

        </Form.Item>
        <Form.Item
          name="timeFrom"
          label="Opening Hours (FROM)"
        >
          <TimePicker format="HH:mm" minuteStep={15} />
        </Form.Item>

        <Form.Item
          name="timeTo"
          label="Opening Hours (TO)"
        >
          <TimePicker format="HH:mm" minuteStep={15} />
        </Form.Item>
        <Form.Item
          label="Opening Hours Description"
          name="openingHoursDesc"

        >
          <ReactQuill />
        </Form.Item>
        <Form.Item
          label="Opening Hours Description in Russian"
          name="openingHoursDescRU"

        >
          <ReactQuill />
        </Form.Item>
        <Form.Item
          label="Opening Hours Description in Arabic"
          name="openingHoursDescAR"

        >
          <ReactQuill />
        </Form.Item>
        <Form.Item
          name="locationName"
          label="Location Name"

        >
          <Input />
        </Form.Item>
        <Form.Item
          name="locationNameRU"
          label="Location Name in Russian"

        >
          <Input />
        </Form.Item>
        <Form.Item
          name="locationNameAR"
          label="Location Name in Arabic"

        >
          <Input dir="rtl" />
        </Form.Item>
        {/* <Form.Item
          name="locationLat"
          label="Latitude"
          rules={[
            {
              required: true,
              message: "Please input Latitude!",
            },
            {
              whitespace: true,
              message: "Please input valid Latitude!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="locationLng"
          label="Longitude"
          rules={[
            {
              required: true,
              message: "Please input Longitude!",
            },
            {
              whitespace: true,
              message: "Please input valid Longitude!",
            },
          ]}
        >
          <Input />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default AddOutletModal;
