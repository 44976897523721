import { Button, Flex, Table, Typography } from "antd";
import axios from "axios";
import { useRef, useState } from "react";
import { useEffect } from "react";
import dayjs from "dayjs";
import { SearchOutlined } from "@ant-design/icons";
import { BiSolidComment } from "react-icons/bi";
import CreateOrderMenu from "./CreateOrderMenu";
import { useSelector } from "react-redux";
import ViewOrderModal from "./ViewOrderModal";
import "../../assets/scss/General.scss";
import TableSearch from "../../components/TableSearch";
import DownloadDataButton from "../../components/DownloadDataButton";
import AssignStaffSelect from "./AssignStaffSelect";
import DateFilterDropdown from "./DateFilterDropdown";
import OrderStatusSelect from "./OrderStatusSelect";
import { onMessageListener } from "../../firebase/firebase";

const Orders = () => {
  const axiosSource = useRef(axios.CancelToken.source());
  const adminRoles = [1, 12, 3, 4, 14, 5, 9, 7]; // Removed supervisor roles
  const supervisorRoles = [5, 7, 9]; // Supervisor roles
  const staffRoles = [6, 8, 10]; // Staff roles
  const assigneeServices = ["Housekeeping", "Maintenance", "Room Service"];
  const [data, setData] = useState();
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();
  const [orderDetailsModal, setOrderDetailsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState([dayjs(), dayjs()]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: false,
    },
  });
  const [statusFilters, setStatusFilters] = useState({
    pending: 1,
    done: 0,
    cancelled: 0,
    inProgress: 1
  });

  const user = JSON.parse(localStorage.getItem("user"));
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const selectedHotelRef = useRef(selectedHotel);
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    selectedHotelRef.current = selectedHotel; // Keep updating the ref whenever selectedHotel changes
  }, [selectedHotel]);
  const searchInput = useRef(null);
  const handleSearch = (_, confirm) => {
    confirm();
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };
  const roleServiceMap = {
    5: "Maintenance", // Maintenance Supervisor
    6: "Maintenance", // Maintenance Staff
    7: "Housekeeping", // Housekeeping Supervisor
    8: "Housekeeping", // Housekeeping Staff
    9: "Room Service", // Room Service Supervisor
    10: "Room Service", // Room Service Staff
    // Add other roles and services as needed
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <TableSearch
        searchInput={searchInput}
        dataIndex={dataIndex}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        handleSearch={handleSearch}
        handleReset={handleReset}
        clearFilters={clearFilters}
        confirm={confirm}
        close={close}
      />
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#0d375c" : undefined,
        }}
      />
    ),

    onFilter: (value, record) => {
      const roomNoValue = record.roomDetails?.roomNo; // Safe access using optional chaining
      if (roomNoValue) {
        return roomNoValue.toString() === value;
      }
      return false; // Return false for rows with no room number
    },
  });

  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      fixed: "left",
      sorter: (a, b) => a.id - b.id, // Sorting by Order ID
      defaultSortOrder: "descend", // Default sorting in descending order
    },
    {
      title: "Item Name",
      dataIndex: "orderItems",
      key: "name",
      render: (details) =>
        details.map((item) => (
          <p key={item.id}>
            {item?.quantity}x {item?.itemDetails?.name?.english}{" "}
            {item?.comment !== null && <BiSolidComment color="#0d375c" />}
          </p>
        )),
      ...getColumnSearchProps("English"), // Adds search for English item names
      onFilter: (value, record) =>
        record.orderItems?.some((item) =>
          item.itemDetails?.name?.english?.toLowerCase().includes(value.toLowerCase())
        ),
    },
    {
      title: "Item Name (AR)",
      dataIndex: "orderItems",
      key: "nameAR",
      render: (details) =>
        details.map((item) => (
          <p key={item.id}>
            {item?.quantity}x {item?.itemDetails?.name?.arabic}{" "}
            {item?.comment !== null && <BiSolidComment color="#0d375c" />}
          </p>
        )),
      ...getColumnSearchProps("Arabic"), // Adds search for Arabic item names
      onFilter: (value, record) =>
        record.orderItems?.some((item) =>
          item.itemDetails?.name?.arabic?.toLowerCase().includes(value.toLowerCase())
        ),
    },

    {
      title: "Service",
      dataIndex: "orderType",
      render: (details) => `${details?.english}`,
      filters: [
        {
          text: "Housekeeping",
          value: "housekeeping",
        },
        {
          text: "Room Service",
          value: "room Service",
        },
        {
          text: "Maintenance",
          value: "maintenance",
        },
        {
          text: "Outlets",
          value: "outlets",
        },
        {
          text: "Excursions",
          value: "excursions",
        },
      ],
      onFilter: (value, record) =>
        record.orderType?.english.toLowerCase() === value.toLowerCase(),
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: [
        { text: "Pending", value: "pending" },
        { text: "Done", value: "done" },
        { text: "Cancelled", value: "cancelled" },
        { text: "In Progress", value: "inProgress" },
      ],
      onFilter: (value, record) => record.status === value,
      defaultFilteredValue: Object.keys(statusFilters).filter(key => statusFilters[key] === 1),
      render: (status, row) => (
        <OrderStatusSelect
          status={status}
          row={row}
          user={user}
          refetch={fetchData} // Refetch on status update
        />
      ),
    },    

    {
      title: "Assigned To",
      dataIndex: ["assignedUserDetails", "id"],
      render: (_, row) => {
        if (row.orderType?.english !== "Front Office") {
          return <AssignStaffSelect user={user} row={row} />;
        } else {
          // Display 'Front Office' text
          return <span>Front Office</span>;
        }
      },
    },

    {
      title: "Room No.",
      dataIndex: ["roomDetails", "roomNo"],
      ...getColumnSearchProps("roomDetails.roomNo"),
    },
    {
      title: "Area",
      dataIndex: ["areaDetails", "name"],
    },
    {
      title: "Create Date",
      dataIndex: "createdAt",
      render: (date) => <div>{dayjs(date).format("DD/MM/YYYY - H:mm")}</div>,
      sorter: (a, b) => dayjs(a.createdAt).isAfter(dayjs(b.createdAt)),
      defaultSortOrder: "descend",
      filterDropdown: () => (
        <DateFilterDropdown
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
        />
      ),

    },
    {
      title: "Comment",
      dataIndex: "comment",
      width: "10%",
    },
    {
      title: "Created By",
      dataIndex: ["creator", "email"],
    },
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "actions",
      render: (_, order) => (
        <Button onClick={() => handleViewClick(order.id)}>Order Details</Button>
      ),
    },
  ];

  const handleViewClick = (orderID) => {
    setSelectedOrder(orderID);
    setOrderDetailsModal(true);
  };

  const handleTableChange = (pagination) => {
    setTableParams(prev => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        current: pagination.current,
        pageSize: pagination.pageSize,
      }
    }));
  };


  const fetchData = (page = 1, pageSize = 10) => {
    setLoading(true);

    // Cancel previous request
    axiosSource.current.cancel('Operation canceled by new request');
    axiosSource.current = axios.CancelToken.source();

    const startDate = dateFilter?.[0]?.format("YYYY-MM-DD");
    const endDate = dateFilter?.[1]?.format("YYYY-MM-DD");

    const params = {
      hotel: selectedHotel.hotelID,
      limit: 10000, // Fetch all data
      ...(startDate && { startDate }),
      ...(endDate && { endDate })
    };

    axios.get(`${import.meta.env.VITE_APP_BASE_API_URL}/orders/get-orders`, {
      params,
      headers: {
        Authorization: `Bearer ${user?.data?.accessToken}`
      },
      cancelToken: axiosSource.current.token
    }).then(res => {
      const { orders } = res.data;
      setData(orders);

      // ✅ Count rows for each status
      const statusCounts = {
        pending: orders.filter(order => order.status === 'pending').length,
        done: orders.filter(order => order.status === 'done').length,
        cancelled: orders.filter(order => order.status === 'cancelled').length,
        inProgress: orders.filter(order => order.status === 'inProgress').length
      };

      // ✅ Sum selected statuses for total count
      const totalSelectedCount = Object.keys(statusFilters)
        .filter(status => statusFilters[status] === 1) // Only count selected statuses
        .reduce((sum, status) => sum + statusCounts[status], 0);

      console.log("Status counts:", statusCounts);
      console.log("Total selected count:", totalSelectedCount);

      setTableParams(prev => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          current: page,
          pageSize: pageSize,
          total: totalSelectedCount, // Use selected statuses total count
        }
      }));
    }).catch(err => {
      if (!axios.isCancel(err)) {
        console.error("Fetch error:", err);
      }
    }).finally(() => {
      setLoading(false);
    });
  };
  const handleStatusFilterChange = (selectedFilters) => {
    const updatedStatusFilters = {
      pending: selectedFilters.includes("pending") ? 1 : 0,
      done: selectedFilters.includes("done") ? 1 : 0,
      cancelled: selectedFilters.includes("cancelled") ? 1 : 0,
      inProgress: selectedFilters.includes("inProgress") ? 1 : 0,
    };

    setStatusFilters(updatedStatusFilters);
    fetchData(); // Refetch data when the filter changes
  };

  useEffect(() => {
    fetchData(tableParams.pagination.current, tableParams.pagination.pageSize);
  }, [selectedHotel, dateFilter, tableParams.pagination.current, tableParams.pagination.pageSize]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData(tableParams.pagination.current, tableParams.pagination.pageSize);
    }, 50000);

    return () => {
      clearInterval(interval);
      axiosSource.current.cancel('Component unmounted');
    };
  }, [tableParams.pagination.current, tableParams.pagination.pageSize]);


  useEffect(() => {
    const initializeListener = async () => {
      try {
        await onMessageListener();
        setRerender(true);
      } catch (error) {
        console.log("Error with Firebase listener", error);
      }
    };

    initializeListener(); // Initialize listener on component mount
  }, []); // Empty dependency array ensures it runs once on mount
  // NOTE:  Stringifying the object to compare its previous state correctly, could be changed with lodash isEqual for better performance
  useEffect(() => {
    if (rerender) {
      fetchData();
    }
  }, [rerender]);

  const handleRowTimer = (record) => {
    const biggestTimer = Math.max(
      record.orderItems.map((item) => item.itemDetails.timer)
    );
    if (
      biggestTimer <
      Math.abs(
        dayjs(record.createdAt).diff(dayjs(), "minute") &&
        record.status !== "done"
      )
    ) {
      return "late";
    }
  };

  return (
    <div>
      <div className="table-header">
        <Typography.Title level={2}>Orders List</Typography.Title>
        <Flex gap={16} className="buttons-wrapper">
          {adminRoles.includes(user.roles.id) ? (
            [9].includes(user?.roles?.id) ?
              null :
              <Button type="primary" onClick={() => setAddModalOpen(true)}>
                Create Order
              </Button>
          ) : (
            ""
          )}

          <DownloadDataButton style={{ marginLeft: "auto" }} data={data} />
        </Flex>
      </div>
      <Table
        rowClassName={(record) => handleRowTimer(record)}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={{
          current: tableParams.pagination.current,
          pageSize: tableParams.pagination.pageSize,
          total: tableParams.pagination.total, // Set the total number of records
        }}
        loading={loading}
        onChange={handleTableChange}
        scroll={{
          x: 1700,
        }}
        className="orders-table"
      />

      {orderDetailsModal && (
        <ViewOrderModal
          orderDetailsModal={orderDetailsModal}
          setOrderDetailsModal={setOrderDetailsModal}
          orderID={selectedOrder}
          refetch={fetchData}
        />
      )}
      {addModalOpen && (
        <CreateOrderMenu
          setAddModalOpen={setAddModalOpen}
          addModalOpen={addModalOpen}
          refetch={fetchData}
        />
      )}
    </div>
  );
};

export default Orders;
