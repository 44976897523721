import { Form, Input, Modal, TimePicker } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Swal from "sweetalert2";
import UploadItemIcon from "../../components/UploadIcon";
import { useState, useEffect } from "react";
import ReactQuill from "react-quill";

dayjs.extend(customParseFormat);

const EditOutletModal = ({
  setEditItemModal,
  editItemModal,
  item,
  refetch,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [extraFieldsParsed, setExtraFieldsParsed] = useState(null);
  const [desc, setDesc] = useState(""); // Store the description content
  const [descAR, setDescAR] = useState(""); // Store Arabic description
  const [descRU, setDescRU] = useState(""); // Store Russian description
  const [image, setImage] = useState("");
  const [openingHoursFrom, setOpeningHoursFrom] = useState("00:00");
  const [openingHoursTo, setOpeningHoursTo] = useState("00:00");
  const [isReady, setIsReady] = useState(false); // New state to track if modal is ready
  const user = JSON.parse(localStorage.getItem("user"));
  const formatOpeningHours = (time) => {
    return dayjs(time, "HH:mm", true).isValid() ? time : "00:00";
  };

  useEffect(() => {
    if (item?.icon) setImage(item.icon);

    if (item?.extraFields) {
      try {
        const parsedExtraFields = typeof item.extraFields === "string"
          ? JSON.parse(item.extraFields)
          : item.extraFields;
        const parsedExtraFieldsDescription = typeof item.description === "string"
          ? JSON.parse(item.description)
          : item.description;
        setExtraFieldsParsed(parsedExtraFields);

        // Assign the description HTML content to state variables
        setDesc(parsedExtraFieldsDescription?.english || "");
        setDescAR(parsedExtraFieldsDescription?.arabic || "");
        setDescRU(parsedExtraFieldsDescription?.russian || "");
        form.setFieldsValue({
          description: desc,
          descriptionAR: descAR,
          descriptionRU: descRU,
          openingHoursDesc: extraFieldsParsed?.openingHours?.description.english,
          openingHoursDescAR: extraFieldsParsed?.openingHours?.description.arabic,
          openingHoursDescRU: extraFieldsParsed?.openingHours?.description.russian,
        });
        if (parsedExtraFields.openingHours) {
          setOpeningHoursFrom(formatOpeningHours(parsedExtraFields.openingHours.from));
          setOpeningHoursTo(formatOpeningHours(parsedExtraFields.openingHours.to));
        }

        setIsReady(true); // Set the modal as ready when data is fully loaded
      } catch (e) {
        console.error("Error parsing extraFields:", e);
      }
    }
  }, [item, editItemModal]);


  const onFinish = async (values) => {
    setLoading(true);

    const openingHoursFrom = values.time && values.time[0].isValid() ? values.time[0].format("HH:mm") : "00:00";
    const openingHoursTo = values.time && values.time[1].isValid() ? values.time[1].format("HH:mm") : "00:00";

    const payload = {
      name: item.name,
      extraFields: {
        phone: values.phone || extraFieldsParsed?.phone,
        outletType: {
          english: values.outletType || extraFieldsParsed?.outletType?.english,
          russian: values.outletTypeRU || extraFieldsParsed?.outletType?.russian,
          arabic: values.outletTypeAR || extraFieldsParsed?.outletType?.arabic,
        },
        openingHours: {
          from: openingHoursFrom,
          to: openingHoursTo,
          description: {
            english: values.openingHoursDesc || extraFieldsParsed?.openingHours?.description?.english,
            arabic: values.openingHoursDescAR || extraFieldsParsed?.openingHours?.description?.arabic,
            russian: values.openingHoursDescRU || extraFieldsParsed?.openingHours?.description?.russian,
          },
        },
        location: {
          name: {
            english: values.locationName || extraFieldsParsed?.location?.name?.english,
            russian: values.locationNameRU || extraFieldsParsed?.location?.name?.russian,
            arabic: values.locationNameAR || extraFieldsParsed?.location?.name?.arabic,
          },

        },
        description: {
          english: desc,
          russian: descRU,
          arabic: descAR,
        },
      },
      icon: image,
    };

    // Initialize the description object if it doesn't exist
    if (!payload.description) {
      payload.description = {};
    }

    // Add descriptions only if they are not empty or null
    if (!(desc == "" || desc == null)) {
      payload.description.english = desc;
    }
    if (!(descAR == "" || descAR == null)) {
      payload.description.arabic = descAR;
    }
    if (!(descRU == "" || descRU == null)) {
      payload.description.russian = descRU;
    }

    // Remove 'description' if it's an empty object
    if (!Object.keys(payload.description || {}).length) {
      delete payload.description;
    }

    try {
      await axios.patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/items?id=${item.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );
      refetch();
      setEditItemModal(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "Something went wrong!",
      });
    } finally {
      setLoading(false);
    }
  };


  const handleCancel = () => {
    form.resetFields();
    setEditItemModal(false);
  };

  if (!isReady) return <div>Loading...</div>; // Only show modal when data is ready
  return (
    <Modal
      confirmLoading={loading}
      okText="Edit item"
      onOk={() => {
        form.validateFields().then((values) => onFinish(values)).catch((info) => console.log("Validate Failed:", info));
      }}
      onCancel={handleCancel}
      open={editItemModal}
      forceRender
      centered
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          icon: item?.icon,
          phone: extraFieldsParsed?.phone,
          outletType: extraFieldsParsed?.outletType?.english,
          outletTypeRU: extraFieldsParsed?.outletType?.russian,
          outletTypeAR: extraFieldsParsed?.outletType?.arabic,
          time: [
            dayjs(openingHoursFrom, "HH:mm"),
            dayjs(openingHoursTo, "HH:mm"),
          ],

          locationName: extraFieldsParsed?.location?.name?.english,
          locationNameRU: extraFieldsParsed?.location?.name?.russian,
          locationNameAR: extraFieldsParsed?.location?.name?.arabic,
        }}
      >
        <Form.Item name="icon" rules={[{ required: true, message: "Please input the restaurant phone number!" }]}>
          <UploadItemIcon
            value={image || ""}
            onChange={(imageURL) => {
              setImage(imageURL);
            }}
          />
        </Form.Item>
        <Form.Item name="outletType" label="Outlet Type" rules={[{ required: true, message: "Please input the restaurant phone number!" }]}>
          <Input />
        </Form.Item>
        <Form.Item name="outletTypeRU" label="Outlet Type in Russian" rules={[{ required: true, message: "Please input the restaurant phone number!" }]}>
          <Input />
        </Form.Item>
        <Form.Item name="outletTypeAR" label="Outlet Type in Arabic" rules={[{ required: true, message: "Please input the restaurant phone number!" }]}>
          <Input dir="rtl" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Restaurant Phone"
        >
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <ReactQuill theme="snow" value={desc} onChange={setDesc} />
        </Form.Item>
        <Form.Item label="Description in Russian" name="descriptionRU">
          <ReactQuill theme="snow" value={descRU} onChange={setDescRU} />
        </Form.Item>
        <Form.Item label="Description in Arabic" name="descriptionAR">
          <ReactQuill theme="snow" value={descAR} onChange={setDescAR} />
        </Form.Item>
        <Form.Item label="Opening Hours" name="time">
          <TimePicker.RangePicker format="HH:mm" />
        </Form.Item>
        <Form.Item name="openingHoursDesc" label="Opening Hours Description">
          <ReactQuill />
        </Form.Item>
        <Form.Item name="openingHoursDescRU" label="Opening Hours Description in Russian">
          <ReactQuill />
        </Form.Item>
        <Form.Item name="openingHoursDescAR" label="Opening Hours Description in Arabic">
          <ReactQuill />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditOutletModal;
